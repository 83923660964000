.business-login-mantis-logo{
    width: 350px;
    text-align: left;
    margin-top: 300px;
}

.business-login-container{
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top:220px;
    
}

.business-login-title{
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.business-login-input{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
}

.business-login-button{
    width: 200px;
    height:40px;
    background-color: #282828;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    border-color: #282828;
    font-family: "Poppins", sans-serif;
    &:hover{
        background-color: #000000;
        border-color: #000000;
    }
}

.business-login-signup-link{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #282828;
    cursor: pointer;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
    &:hover{
        color: #000000;
    }
}

.business-login-toast{
    background-color: white;
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
    position: fixed;
    top: 20px;
    right: 450px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.business-login-toast.visible {
    opacity: 1; 
}